import React from "react"
import "./contact.scss";



export default function Success(){
    return (
        <div class="success-message">
            <p>Thank you for dropping by, I recived your message.</p>
            <p>I will get back to you shortly</p>
        </div>
    )
}